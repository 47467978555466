export function dropdown(selector) {
  const dropdowns = document.querySelectorAll(selector)

  if (dropdowns) {
    dropdowns.forEach(function (dropdown) {
      dropdown.addEventListener('click', function (event) {
        event.stopPropagation()
        dropdown.classList.toggle('is-active')
      })
    })
    window.onclick = function (event) {
      if (!event.target.matches('.dropdown')) {
        dropdowns.forEach(dropdown => dropdown.classList.remove('is-active'))
      }
    }
  }
}