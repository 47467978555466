export function search(searchSelector, rowSelector, dataAttr) {
  const input = document.querySelector(searchSelector)
  const rows = document.querySelectorAll(rowSelector)

  if (input) input.addEventListener('input', () => searchInTable(input.value))

  function searchInTable(value) {
    rows.forEach(row => {
      const cellsSearchable = row.querySelectorAll(dataAttr)

      let isInclude = false

      cellsSearchable.forEach(function (cell) {
        if (!isInclude) isInclude = cell.dataset.tableItem.includes(value)
      })

      if (!isInclude) row.style.display = 'none'
      else row.style = null

      if (value.length === 0) row.style = null
    })
  }
}

export function tableMobile(mainSelector, dataAttr, buttonSelector, selectedSelector) {
  const mainWrapper = document.querySelector(mainSelector)
  if (mainWrapper) {
    const input = mainWrapper.querySelector('form input')
    const select = mainWrapper.querySelector('form select')
    const dataWrappers = mainWrapper.querySelectorAll(dataAttr)
    const buttons = mainWrapper.querySelectorAll(buttonSelector)

    if (input) input.addEventListener('input', () => {
      deactvateAll()
      select.value = ''
      activateByValue(input.value)
    })

    if (select) select.addEventListener('change', () => {
      deactvateAll()
      input.value = ''
      activateByValue(select.value)
    })

    const deactvateAll = function () {
      dataWrappers.forEach(function (wrapper) {
        wrapper.classList.remove('is-active')
      })
    }

    const activateByValue = function (value) {
      const wrapper = mainWrapper.querySelector(`${selectedSelector}[data-table-item="${value}"]`)
      if (wrapper) {
        document.getElementById('table-compatibility-mobile-selected').scrollIntoView({
          block: "center",
          behavior: "smooth"
        })
        wrapper.classList.add('is-active')
      } else deactvateAll()
    }

    buttons.forEach(function (button) {
      button.addEventListener('click', function (event) {
        input.value = ''
        select.value = ''
        deactvateAll()
        button.classList.add('is-active')
        activateByValue(event.target.dataset.tableItem)
      })
    })
  }
}