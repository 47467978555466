export function modal(modalSelector, buttonSelector) {

  const modal = document.querySelector(modalSelector)
  const buttons = document.querySelectorAll(buttonSelector)
  const closeButtons = document.querySelectorAll('.close-modal')
  const closeBackgrounds = document.querySelectorAll('.modal-background')

  if (buttons.length && modal) {
    buttons.forEach(function (button) {
      button.addEventListener('click', function (event) {
        event.stopPropagation()
        modal.classList.toggle('is-active')
      })
    })
  }

  if (closeBackgrounds.length) {
    closeBackgrounds.forEach(function (button) {
      button.addEventListener('click', function (event) {
        event.stopPropagation()
        modal.classList.remove('is-active')
      })
    })
  }

  if (closeButtons.length) {
    closeButtons.forEach(function (button) {
      button.addEventListener('click', function (event) {
        event.stopPropagation()
        modal.classList.remove('is-active')
      })
    })
  }
}