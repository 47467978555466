import { sliderAbout, sliderPartners, sliderEffect, sliderOffer } from './sliders'
import { collapse } from './collapse'
import { universalTabs } from './tabs'
import { cart, counter } from './cart'
import { modal } from './modal'
import { navbar } from './navbar'
import { dropdown } from './dropdown'
import { search, tableMobile } from './table'
import tippy from 'tippy.js'

tippy('.tooltip', {
  trigger: 'click',
  content: (reference) => {
    return `<a href='${reference.dataset.tooltipLink}' target='_blank' rel='noopener noreferrer' style='color: white'>${reference.dataset.tooltipTitle}</a>`
  },
  interactive: true,
  allowHTML: true
})

sliderAbout('#slider-about')
sliderPartners('#slider-partners')
sliderEffect('#effect-offers')
sliderOffer('#slider-offer')

universalTabs('#contacts-panel .panel-block', '#contacts-panel-content .contacts-panel-content-item')
universalTabs('.tabs li', '.tab-content')
universalTabs('.catalog .panel-block', '.tab-content')
universalTabs('#table-color .panel-block', '#table-color .tab-content')
universalTabs('.modal-selection-tabs button', '.modal-selection-tab-content > .tab-content')

collapse('#faq', 'li.faq-panel', '.faq-panel-toggle')
collapse('#collapse', 'li.collapse-panel', '.collapse-panel-toggle')

navbar('.navbar-mobile-gamburger', '.navbar-mobile-popup', '.navbar-mobile-close')

dropdown('.dropdown')
search('#table-compatibility input', '.table-compatibility table tbody tr', '[data-table-item]')
tableMobile('#table-compatibility-mobile', '#table-compatibility-mobile [data-table-item]', '.table-compatibility-mobile-item', '.table-compatibility-mobile-selected')

cart('#cart', 'main')
counter('.cart-counter')

modal('.modal-1', '.modal-action-1')
modal('.modal-2', '.modal-action-2')
modal('.modal-3', '.modal-action-3')
modal('.modal-4', '.modal-action-4')
modal('.modal-5', '.modal-action-5')
modal('.modal-6', '.modal-action-6')