export function cart(actionSelector, wrapperSelector) {
  const button = document.querySelector(actionSelector)
  const wrapper = document.querySelector(wrapperSelector)

  const toggle = 'cart-modal-active'

  button.addEventListener('click', function (event) {
    event.stopPropagation()
    wrapper.classList.toggle(toggle)
  })

  wrapper.addEventListener('click', function (event) {
    event.stopPropagation()
    if (event.target.classList.contains('cart-modal') || event.target.classList.contains(toggle)) wrapper.classList.toggle(toggle)
  })
}

export function counter(selector) {
  const wrappers = document.querySelectorAll(selector)

  wrappers.forEach(function(wrapper) {
    const input = wrapper.querySelector('input')
    const buttons = wrapper.querySelectorAll('.button')
    const minus = buttons[0]
    const plus = buttons[1]

    minus.addEventListener('click', function (event) {
      if (+input.value) input.value = +input.value - 1
    })

    plus.addEventListener('click', function (event) {
      input.value = +input.value + 1
    })
  })
}