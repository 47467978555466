export function collapse(collapseSelector, panelSelector, toggleSelector) {
  const panels = document.querySelectorAll(`${collapseSelector} > ${panelSelector}`)

  const plus = '&plus;'
  const minus = '&minus;'



  panels.forEach(function (panel) {
    const toggle = panel.querySelector(toggleSelector)
    toggle.addEventListener('click', function () {
      const isActive = panel.classList.contains('is-active')
      if (isActive) {
        panel.classList.remove('is-active')
        panel.querySelector(toggleSelector).innerHTML = plus
      } else {
        panel.classList.add('is-active')
        panel.querySelector(toggleSelector).innerHTML = minus
      }
    })
  })
}